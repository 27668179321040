<script setup lang="ts">
import { useRootStore } from "@/store/root";
import TypeText from "@/components/marketing/TypeText.vue";

const rootStore = useRootStore();

const menus = [
  {
    name: "Employers",
    links: [
      {
        name: "Getting started",
        route: ROUTES.employers,
      },
      {
        name: "Features",
        route: ROUTES.employersFeatures,
      },
      {
        name: "Pricing",
        route: ROUTES.employersPricing,
      },
      // {
      //   name: "Browse candidates",
      //   route: ROUTES.employersBrowseCandidates,
      // },
    ],
  },
  {
    name: "Candidates",
    links: [
      {
        name: "Getting started",
        route: ROUTES.candidates,
      },
      {
        name: "Features",
        route: ROUTES.candidatesFeatures,
      },
      {
        name: "Download the app",
        action: () => rootStore.toggleDownloadAppModal(true),
      },
    ],
  },
  {
    name: "Hirable",
    links: [
      {
        name: "Blog",
        route: ROUTES.blog,
      },
      {
        name: "Contact",
        route: ROUTES.contact,
      },
      {
        name: "Delete account",
        route: ROUTES.howToDeleteAccount,
      },
    ],
  },
  {
    name: "Connect",
    links: [
      {
        name: "Facebook",
        route: EXTERNAL_LINKS.facebook,
      },
      {
        name: "Instagram",
        route: EXTERNAL_LINKS.instagram,
      },
      {
        name: "Twitter",
        route: EXTERNAL_LINKS.twitter,
      },
      {
        name: "LinkedIn",
        route: EXTERNAL_LINKS.linkedIn,
      },
      {
        name: "TikTok",
        route: EXTERNAL_LINKS.tiktok,
      },
    ],
  },
];
</script>

<template>
  <footer class="flex flex-col items-center justify-center bg-white-gray">
    <div class="container">
      <div class="grid grid-cols-10 gap-5 row pb-10 px-10 xl:px-0 pt-[52px]">
        <div class="col-span-10 mt-12 lg:col-span-2">
          <BaseImage height="2rem" width="144px" src="/hirable-logo.png" :alt="$config.public.appName" />
          <div class="mt-6 text-sm">
            <TypeText text="Placing people, not paper." />
          </div>
        </div>
        <div v-for="menu in menus" :key="menu.name" class="col-span-10 mt-12 lg:col-span-2">
          <h6 class="mb-4 font-primary font-semibold lg:mb-8">{{ menu.name }}</h6>
          <ul>
            <li v-for="(nav, index) in menu.links" :key="index" :class="menu.links.length - 1 === index ? 'mb-0' : 'mb-2'">
              <div v-if="nav.action" @click="nav.action" class="footer-link">{{ nav.name }}</div>
              <NuxtLink v-else :to="nav.route" class="footer-link">{{ nav.name }}</NuxtLink>
            </li>
          </ul>
        </div>

        <!-- <div class="col-span-10 mt-12 lg:col-span-2">
          <h6 class="mb-4 font-primary font-semibold lg:mb-8">Location</h6>
          <ul class="text-sm">
            <li class="mb-2">{{ $config.public.companyAddress }}</li>
            <li class="mb-2">
              <a class="mb-2 link" :href="`mailto:${$config.public.appEmail}`">{{ $config.public.appEmail }}</a>
            </li>
            <li>
              <p>{{ $config.public.companyTel }}</p>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="container max-w-[1440px] px-5">
      <div class="mx-auto border-t py-7 text-center">
        <p class="text-sm text-dark-gray">
          Copyright © {{ dayjs().year() }} {{ $config.public.appName }} | All Rights Reserved |
          <span class="link" @click="navigateTo(ROUTES.privacyPolicy)">Privacy Policy</span> |
          <span class="link" @click="navigateTo(ROUTES.termsAndConditions)">Terms & Conditions</span>
        </p>
      </div>
    </div>
  </footer>
</template>
